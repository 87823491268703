import { displayMap } from './mapbox';
import { login, logout } from './login';

console.log('Hello from index.js');
// DOM Elements
const mapBox = document.getElementById('map');
const loginForm = document.querySelector('.form');
const email = document.getElementById('email');
const password = document.getElementById('password');
const logOutBtn = document.querySelector('.nav__el--logout');

// display map only if mapbox exists
// get locations from data attribute
if (mapBox) {
  const locations = JSON.parse(mapBox.dataset.locations);
  // console.log(locations);
  displayMap(locations);
}

// get login details from page and send to api
if (loginForm) {
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    // get email and password
    const emailValue = email.value;
    const passwordValue = password.value;
    // log user in
    login(emailValue, passwordValue);
  });
}

if (logOutBtn)
  logOutBtn.addEventListener('click', (e) => {
    e.preventDefault();
    // log out user
    logout();
  });
