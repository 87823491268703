/* eslint-disable-next-line */
import axios from 'axios';
import testKey, { showAlert } from './alerts';

export const login = async (email, password) => {
  // console.log(email, password);
  console.log(testKey);
  try {
    const res = await axios({
      method: 'POST',
      url: 'http://127.0.0.1:3000/api/v1/users/login',
      data: {
        email: email,
        password,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Logged in successfully!');
      window.setTimeout(() => {
        // reload to root page after 1500 seconds
        location.assign('/');
      }, 1500);
    }
    // console.log(res);
  } catch (err) {
    showAlert('error', err.response.data.message);
    // console.log(err.response.data);
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: 'http://127.0.0.1:3000/api/v1/users/logout',
    });
    // do a true reload from server, to reflect logged out change
    // if ((res.data.status = 'success')) location.reload(true);
    if (res.data.status === 'success') {
      showAlert('success', 'Logging out!');
      window.setTimeout(() => {
        // reload to root page after 1500 seconds
        location.reload('true');
      }, 1200);
    }
  } catch (err) {
    showAlert('error', 'Error logging out! Try again.');
  }
};
